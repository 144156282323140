import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { rem } from '~/src/helpers/rem-converter.helper';
import MtsLoaderSvg from '~/src/images/mts-loader.svg';
import * as MTSFonts from '~/public/fonts';
import { COLORS } from '~/src/styles/tokens/colors';

const SCROLL_OFFSET = 120;

export const GlobalStyles = createGlobalStyle`
    ${reset}

    html, body {
        box-sizing: border-box;
        scroll-behavior: smooth;
    }
    
    html {
        height: 100%;
        background-image: url(${MtsLoaderSvg});
        scroll-padding-top: ${SCROLL_OFFSET}px;
        background-repeat: no-repeat;
        background-position: center;
        color: ${COLORS.textLightPrimary};
    }
    
    *, *:before, *:after {
        box-sizing: inherit;
    }
    
    &:root {
        --text-headline: #000000;
	    --deprecated-headline: #000000;
        --text-main-heading: #3e3e3e;
        --text-dark-primary: #fafafa;
	    --text-light-inverted: #fafafa;
        --text-light-primary: #1d2023;
	    --text-light-tertiary: #969FA8;
	    --text-primary: #1d2023;
        --text-secondary: #626c77;
	    --text-light-secondary: #626c77;
        --text-tertiary: #969fa8;
        --text-inverted: #ffffff;
        --text-positive: #12b23f;
        --text-negative: #eb4a13;
        --text-light-negative: #D8400C;
        --text-primary-link: #007cff;
        --text-light-primary-link: #0070E5;
        --text-dark-primary-link: #45B6FC;
        --text-dark-primary-link-inverted: #0070E5;
        --text-primary-link-dark: #45B6FC;
        --text-secondary-link: #969fa8;
        --controls-light-stroke-radio: #8D969F;
        --background-primary: #ffffff;
        --background-primary-transparent: rgba(255, 255, 255, 0.7);
        --background-dark-primary: #000000;
	    --background-dark-modal: #2C3135;
	    --background-light-inverted: #1d2023;
        --background-primary-elevated: #ffffff;
        --background-modal: #ffffff;
        --background-stroke: rgba(188, 195, 208, 0.5);
	    --background-light-secondary: #f2f3f7;
        --background-light-disabled: #f8f8f8;
        --background-light-overlay: rgba(29, 32, 35, 0.4);
        --background-secondary: #f2f3f7;
        --background-secondary-elevated: #ffffff;
        --background-inverted: #1d2023;
        --background-light-primary: rgba(255, 255, 255, 1);
	    --background-light-stroke: rgba(188, 195, 208, 0.5);
	    --background-light-primary-elevated: rgba(255, 255, 255, 1);
	    --background-light-secondary-disabled: rgba(248, 248, 251, 1);
        --background-overlay: rgba(29, 32, 35, 0.4);
        --background-hover: rgba(188, 195, 208, 0.25);
        --background-navbar-ios: rgba(255, 255, 255, 0.92);
        --icons-primary: #000000;
        --icons-primary-close: rgba(29, 32, 35, 1);
        --icons-secondary: #969fa8;
        --icons-tertiary: #bbc1c7;
        --icon-light-primary: #1D2023;
        --icons-filter-primary: brightness(0) saturate(100%) invert(0%) sepia(90%) saturate(7500%) hue-rotate(59deg) brightness(105%) contrast(111%);
        --icons-filter-primary-link: brightness(0) saturate(100%) invert(47%) sepia(65%) saturate(6818%) hue-rotate(199deg) brightness(102%) contrast(110%);
        --icons-filter-brand-red: brightness(0) saturate(100%) invert(11%) sepia(98%) saturate(6437%) hue-rotate(354deg) brightness(89%) contrast(101%);
        --icons-filter-secondary: brightness(0) saturate(100%) invert(66%) sepia(19%) saturate(166%) hue-rotate(169deg) brightness(94%) contrast(83%);
        --icons-filter-tertiary: brightness(0) saturate(100%) invert(96%) sepia(2%) saturate(1331%) hue-rotate(180deg) brightness(87%) contrast(77%);
        --icons-filter-tertiary-active: brightness(0) saturate(100%) invert(97%) sepia(4%) saturate(158%) hue-rotate(191deg) brightness(98%) contrast(98%);
        --icons-filter-greyscale-0: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(31%) hue-rotate(262deg) brightness(107%) contrast(110%);
        --icons-filter-stroke: brightness(0) saturate(100%) invert(40%) sepia(10%) saturate(282%) hue-rotate(177deg) brightness(91%) contrast(89%);
        --icons-filter-inactive: brightness(0) saturate(100%) invert(96%) sepia(5%) saturate(221%) hue-rotate(176deg) brightness(95%) contrast(90%);
        --icons-filter-none-to-primary: none;
        --icons-filter-inverted: brightness(0) saturate(100%) invert(6%) sepia(3%) saturate(2892%) hue-rotate(169deg) brightness(101%) contrast(86%);
        --controls-primary-active: #ff0032;
        --controls-primary-active-hover: #d40610;
        --controls-secondary-active: #1d2023;
        --controls-light-secondary-active: #1D2023;
        --controls-tertiary-active: #f2f3f7;
	    --controls-light-tertiary-active: #f2f3f7;
        --controls-tertiary-active-hover: #e2e5eb;
        --controls-inactive: rgba(188, 195, 208, 0.5);
        --controls-alternative: #ffffff;
        --controls-active-tabbar: #ff0032;
        --controls-inactive-tabbar: #aeb5bd;
        --controls-light-stroke: #aab0b6;
	    --accent-active: #007cff;
        --accent-positive: #26cd58;
        --accent-warning: #fac031;
        --accent-negative: #f95721;
        --brand-mts-red: #ff0032;
        --constant-main-dark: #e2e2e2;
        --controls-temporary: #fff;
        --constant-greyscale-900: #000000;
        --constant-greyscale-800: #1d2023;
        --constant-greyscale-700: #2c3135;
        --constant-greyscale-600: #434a51;
        --constant-greyscale-500: #626c77;
        --constant-greyscale-400: #969fa8;
        --constant-greyscale-300: #bbc1c7;
        --constant-greyscale-200: #e2e5eb;
        --constant-greyscale-100: #f2f3f7;
        --constant-greyscale-0: #ffffff;
        --constant-skeleton-foreground: #26283c;
        --constant-pale-gray-blue: #F4F5FB;
        --constant-grey-chateau: #9198A0;
        --constant-deep-blue: #001424;
        --constant-darkest-blackberry: #002094;
        --constant-dark-blackberry: #003db7;
        --constant-normal-blackberry: #014fce;
        --constant-light-blackberry: #6384e0;
        --constant-lightest-blackberry: #e7eafa;
        --constant-darkest-blueberry: #0048aa;
        --constant-dark-blueberry: #007cff;
        --constant-normal-blueberry: #0097fd;
        --constant-light-blueberry: #45b6fc;
        --constant-lightest-blueberry: #e1f3fe;
        --constant-darkest-mint: #00724d;
        --constant-dark-mint: #03a17b;
        --constant-normal-mint: #00c19b;
        --constant-light-mint: #00d9bc;
        --constant-lightest-mint: #dff9f5;
        --constant-darkest-apple: #027722;
        --constant-dark-apple: #04aa42;
        --constant-normal-apple: #26cd58;
        --constant-light-apple: #74df8b;
        --constant-lightest-apple: #e8faeb;
        --constant-darkest-lime: #808201;
        --constant-dark-lime: #a6c100;
        --constant-normal-lime: #c1eb1d;
        --constant-light-lime: #d3f36b;
        --constant-lightest-lime: #f8fee7;
        --constant-darkest-banana: #f37f19;
        --constant-dark-banana: #fac031;
        --constant-normal-banana: #fbe739;
        --constant-light-banana: #fdf177;
        --constant-lightest-banana: #fffde8;
        --constant-darkest-orange: #ba360a;
        --constant-dark-orange: #e04a17;
        --constant-normal-orange: #f95721;
        --constant-light-orange: #ffa080;
        --constant-lightest-orange: #fbe9e7;
        --constant-darkest-raspberry: #8e003f;
        --constant-dark-raspberry: #c51345;
        --constant-normal-raspberry: #ea1f49;
        --constant-light-raspberry: #f55f7e;
        --constant-lightest-raspberry: #ffe4e9;
        --constant-darkest-cranberry: #7f3363;
        --constant-dark-cranberry: #bb4079;
        --constant-normal-cranberry: #e54887;
        --constant-light-cranberry: #e677ad;
        --constant-lightest-cranberry: #f9e7f0;
        --constant-darkest-plum: #471c61;
        --constant-dark-plum: #6d2d79;
        --constant-normal-plum: #883888;
        --constant-light-plum: #a86ea7;
        --constant-lightest-plum: #f0e7f0;
        --gradient-sunset: linear-gradient(90.78deg, #6639aa 0.2%, #ef425a 47.74%, #f3cc47 102.75%);
        --gradient-green-blue: linear-gradient(89.93deg, #0e6100 2.47%, #006edc 99.96%);
        --gradient-banner-standart: linear-gradient(90.13deg, #617ad1 6.15%, #ff6ff1 96.49%);
        --gradient-purple: linear-gradient(90.13deg, #617ad1 6.15%, #ff6ff1 96.49%);
        --gradient-plum-cranberry: linear-gradient(89.93deg, #8d0077 2.47%, #006edc 99.96%);
        --gradient-orange-raspberry: linear-gradient(89.93deg, #ff7b01 2.47%, #dc0035 99.96%);
        --gradient-mint-blue: linear-gradient(90.36deg, #d7ffe2 2.06%, #d9ebff 96.5%);
        --gradient-purple-blue: linear-gradient(40.97deg, #F7F0FF 15.47%, #698FF0 100%);
        --gradient-red-purple-violet: linear-gradient(261.68deg, #d62d2d 45.05%, #8d1089 89.32%, #520092 107.55%);
	    --gradient-referral-urls-banner: linear-gradient(107.44deg, #DEE3FF 38.05%, #FDD6C1 114.13%);
        --gradient-light-blue-violet: linear-gradient(246.52deg, #c8c4ff 11.79%, #c4cfff 48.27%, #c0d9ff 82.53%);
    }
    
    * {
        overflow-wrap: break-word;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
    img,
    video,
    code,
    pre {
        max-width: 100%;
    }
    
    html,
    button,
    input,
    select,
    textarea {
        font: inherit;
    }
    
    figure.table {
        overflow-x: auto;
    }
    
    table, th, td {
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid #bbc1c7;
    }
    
    ol {
        list-style: decimal;
    }
    
    .prp-logo__text {
        top: 0 !important;
    }
    
    .hidden {
        display: none !important;
    }
    
    .arrow-icon {
        padding-right: ${rem(8)};
        padding-left: ${rem(8)};
        align-self: center;
    }
    
    main div {
        display: flex;
        flex-wrap: wrap;
    }
    
    :root {
        --font-sans-bold: ${MTSFonts.MTSSansWebBold.style.fontFamily};
        --font-sans-black: ${MTSFonts.MTSSansWebBlack.style.fontFamily};
        --font-sans-medium: ${MTSFonts.MTSSansWebMedium.style.fontFamily};
        --font-sans-regular: ${MTSFonts.MTSSansWebRegular.style.fontFamily};
        --font-sans-ultraWide: ${MTSFonts.MTSSansUltraWide.style.fontFamily};
        --font-compact-bold: ${MTSFonts.MTSCompactBold.style.fontFamily};
        --font-compact-black: ${MTSFonts.MTSCompactBlack.style.fontFamily};
        --font-compact-medium: ${MTSFonts.MTSCompactMedium.style.fontFamily};
        --font-compact-regular: ${MTSFonts.MTSCompactRegular.style.fontFamily};
        --font-wide-bold: ${MTSFonts.MTSWideBold.style.fontFamily};
        --font-wide-black: ${MTSFonts.MTSWideBlack.style.fontFamily};
        --font-wide-light: ${MTSFonts.MTSWideLight.style.fontFamily};
        --font-wide-medium: ${MTSFonts.MTSWideMedium.style.fontFamily};
        --font-wide-regular: ${MTSFonts.MTSWideRegular.style.fontFamily};
        --font-text-bold: ${MTSFonts.MTSTextBold.style.fontFamily};
        --font-text-black: ${MTSFonts.MTSTextBlack.style.fontFamily};
        --font-text-medium: ${MTSFonts.MTSTextMedium.style.fontFamily};
        --font-text-regular: ${MTSFonts.MTSTextRegular.style.fontFamily};
        --size-8: 8px/12px;
        --size-12: 12px/16px;
        --size-14: 14px/20px;
	    --size-16: 16px/20px;
        --size-17: 17px/24px;
        --size-20: 20px/24px;
        --size-24: 24px/28px;
        --size-26: 26px/30px;
        --size-28: 28px/30px;
        --size-32: 32px/36px;
        --size-36: 36px/40px;
        --size-44: 44px/48px;
        --size-48: 48px/52px;
	    --size-56: 56px/60px;
	    --size-58: 58px/62px;
        --size-64: 64px/68px;
	    --size-72: 72px/78px;
        --size-84: 84px/88px;
        --size-180: 180px/184px;
    }

    #mts-editor ol li, #mts-editor ul li, .ck-editor__main .ck-content ol li, .ck-editor__main .ck-content ul li {
      color: ${COLORS.textLightPrimary}
    }
`;
