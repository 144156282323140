export enum COLORS {
    textHeadline = 'var(--text-headline)',
    textMainHeading = 'var(--text-main-heading)',
    textLightPrimary = 'var(--text-light-primary)',
    textLightTertiary = 'var(--text-light-tertiary)',
    textPrimaryDark = 'var(--text-dark-primary)',
    textLightInverted = 'var(--text-light-inverted)',
    textDarkPrimaryLink = 'var(--text-dark-primary-link)',
    textDarkPrimaryLinkInverted = 'var(--text-dark-primary-link-inverted)',
    textLightPrimaryLink = 'var(--text-light-primary-link)',
    textPrimary = 'var(--text-primary)',
    textSecondary = 'var(--text-secondary)',
    textLightSecondary = 'var(--text-light-secondary)',
    textTertiary = 'var(--text-tertiary)',
    textInverted = 'var(--text-inverted)',
    textPositive = 'var(--text-positive)',
    textNegative = 'var(--text-negative)',
    textLightNegative = 'var(--text-light-negative)',
    textPrimaryLink = 'var(--text-primary-link)',
    textPrimaryLinkDark = 'var(--text-primary-link-dark)',
    textSecondaryLink = 'var(--text-secondary-link)',
    backgroundPrimary = 'var(--background-primary)',
    backgroundPrimaryTransparent = 'var(--background-primary-transparent)',
    backgroundLightPrimary = 'var(background-light-primary)',
    backgroundLightStroke = 'var(--background-light-stroke)',
    backgroundLightPrimaryElevated = 'var(--background-light-primary-elevated)',
    backgroundLightSecondaryDisabled = 'var(--background-light-secondary-disabled)',
    backgroundDarkPrimary = 'var(--background-dark-primary)',
    backgroundDarkModal = 'var(--background-dark-modal)',
    backgroundLightInverted = 'var(--background-light-inverted)',
    backgroundPrimaryElevated = 'var(--background-primary-elevated)',
    backgroundModal = 'var(--background-modal)',
    backgroundStroke = 'var(--background-stroke)',
    controlsLightStrokeRadio = 'var(--controls-light-stroke-radio)',
    backgroundLightSecondary = 'var(--background-light-secondary)',
    backgroundLightOverlay = 'var(--background-light-overlay)',
    backgroundSecondaryElevated = 'var(--background-secondary-elevated)',
    backgroundSecondary = 'var(--background-secondary)',
    backgroundDisabled = 'var(--background-light-disabled)',
    backgroundInverted = 'var(--background-inverted)',
    backgroundOverlay = 'var(--background-overlay)',
    backgroundHover = 'var(--background-hover)',
    backgroundNavbarIos = 'var(--background-navbar-ios)',
    iconsPrimary = 'var(--icons-primary)',
    iconsPrimaryClose = 'var(--icons-primary-close)',
    iconsSecondary = 'var(--icons-secondary)',
    iconsTertiary = 'var(--icons-tertiary)',
    iconLightPrimary = 'var(--icon-light-primary)',
    iconsFilterPrimary = 'var(--icons-filter-primary)',
    iconsFilterPrimaryLink = 'var(--icons-filter-primary-link)',
    iconsFilterBrandRed = 'var(--icons-filter-brand-red)',
    iconsFilterSecondary = 'var(--icons-filter-secondary)',
    iconsFilterTertiary = 'var(--icons-filter-tertiary)',
    iconsFilterTertiaryActive = 'var(--icons-filter-tertiary-active)',
    iconsFilterGreyscale = 'var(--icons-filter-greyscale-0)',
    iconsFilterStroke = 'var(--icons-filter-stroke)',
    iconsFilterInactive = 'var(--icons-filter-inactive)',
    iconsFilterNoneToPrimary = 'var(--icons-filter-none-to-primary)',
    iconsFilterInverted = 'var(--icons-filter-inverted)',
    controlsPrimaryActive = 'var(--controls-primary-active)',
    controlsPrimaryActiveHover = 'var(--controls-primary-active-hover)',
    controlsSecondaryActive = 'var(--controls-secondary-active)',
    controlsLightSecondaryActive = 'var(--controls-light-secondary-active)',
    controlsTertiaryActive = 'var(--controls-tertiary-active)',
    controlsLightTertiaryActive = 'var(--controls-light-tertiary-active)',
    controlsTertiaryActiveHover = 'var(--controls-tertiary-active-hover)',
    controlsInactive = 'var(--controls-inactive)',
    controlsAlternative = 'var(--controls-alternative)',
    controlsActiveTabBar = 'var(--controls-active-tabbar)',
    controlsInactiveTabBar = 'var(--controls-inactive-tabbar)',
    controlsLightStroke = 'var(--controls-light-stroke)',
    accentActive = 'var(--accent-active)',
    accentPositive = 'var(--accent-positive)',
    accentWarning = 'var(--accent-warning)',
    accentNegative = 'var(--accent-negative)',
    brandMtsRed = 'var(--brand-mts-red)',
    constantMainDark = 'var(--constant-main-dark)',
    controlsTemporary = 'var(--controls-temporary)',
    constantGreyscale900 = 'var(--constant-greyscale-900)',
    constantGreyscale800 = 'var(--constant-greyscale-800)',
    constantGreyscale700 = 'var(--constant-greyscale-700)',
    constantGreyscale600 = 'var(--constant-greyscale-600)',
    constantGreyscale500 = 'var(--constant-greyscale-500)',
    constantGreyscale400 = 'var(--constant-greyscale-400)',
    constantGreyscale300 = 'var(--constant-greyscale-300)',
    constantGreyscale200 = 'var(--constant-greyscale-200)',
    constantGreyscale100 = 'var(--constant-greyscale-100)',
    constantGreyscale0 = 'var(--constant-greyscale-0)',
    constantSkeletonForeground = 'var(--constant-skeleton-foreground)',
    constantPaleGrayBlue = 'var(--constant-pale-gray-blue)',
    constantGreyChateau = 'var(--constant-grey-chateau)',
    constantDeepBlue = 'var(--constant-deep-blue)',
    constantDarkestBlackberry = 'var(--constant-darkest-blackberry)',
    constantDarkBlackberry = 'var(--constant-dark-blackberry)',
    constantNormalBlackberry = 'var(--constant-normal-blackberry)',
    constantLightBlackberry = 'var(--constant-light-blackberry)',
    constantLightestBlackberry = 'var(--constant-lightest-blackberry)',
    constantDarkestBlueberry = 'var(--constant-darkest-blueberry)',
    constantDarkBlueberry = 'var(--constant-dark-blueberry)',
    constantNormalBlueberry = 'var(--constant-normal-blueberry)',
    constantLightBlueberry = 'var(--constant-light-blueberry)',
    constantLightestBlueberry = 'var(--constant-lightest-blueberry)',
    constantDarkestMint = 'var(--constant-darkest-mint)',
    constantDarkMint = 'var(--constant-dark-mint)',
    constantNormalMint = 'var(--constant-normal-mint)',
    constantLightMint = 'var(--constant-light-mint)',
    constantLightestMint = 'var(--constant-lightest-mint)',
    constantDarkestApple = 'var(--constant-darkest-apple)',
    constantDarkApple = 'var(--constant-dark-apple)',
    constantNormalApple = 'var(--constant-normal-apple)',
    constantLightApple = 'var(--constant-light-apple)',
    constantLightestApple = 'var(--constant-lightest-apple)',
    constantDarkestLime = 'var(--constant-darkest-lime)',
    constantDarkLime = 'var(--constant-dark-lime)',
    constantNormalLime = 'var(--constant-normal-lime)',
    constantLightLime = 'var(--constant-light-lime)',
    constantLightestLime = 'var(--constant-lightest-lime)',
    constantDarkestBanana = 'var(--constant-darkest-banana)',
    constantDarkBanana = 'var(--constant-dark-banana)',
    constantNormalBanana = 'var(--constant-normal-banana)',
    constantLightBanana = 'var(--constant-light-banana)',
    constantLightestBanana = 'var(--constant-lightest-banana)',
    constantDarkestOrange = 'var(--constant-darkest-orange)',
    constantDarkOrange = 'var(--constant-dark-orange)',
    constantNormalOrange = 'var(--constant-normal-orange)',
    constantLightOrange = 'var(--constant-light-orange)',
    constantLightestOrange = 'var(--constant-lightest-orange)',
    constantDarkestRaspberry = 'var(--constant-darkest-raspberry)',
    constantDarkRaspberry = 'var(--constant-dark-raspberry)',
    constantNormalRaspberry = 'var(--constant-normal-raspberry)',
    constantLightRaspberry = 'var(--constant-light-raspberry)',
    constantLightestRaspberry = 'var(--constant-lightest-raspberry)',
    constantDarkestCranberry = 'var(--constant-darkest-cranberry)',
    constantDarkCranberry = 'var(--constant-dark-cranberry)',
    constantNormalCranberry = 'var(--constant-normal-cranberry)',
    constantLightCranberry = 'var(--constant-light-cranberry)',
    constantLightestCranberry = 'var(--constant-lightest-cranberry)',
    constantDarkestPlum = 'var(--constant-darkest-plum)',
    constantDarkPlum = 'var(--constant-dark-plum)',
    constantNormalPlum = 'var(--constant-normal-plum)',
    constantLightPlum = 'var(--constant-light-plum)',
    constantLightestPlum = 'var(--constant-lightest-plum)',
    gradientSunset = 'var(--gradient-sunset)',
    gradientGreenBlue = 'var(--gradient-green-blue)',
    gradientBannerStandart = 'var(--gradient-banner-standart)',
    gradientPurple = 'var(--gradient-purple)',
    gradientPlumCranberry = 'var(--gradient-plum-cranberry)',
    gradientOrangeRaspberry = 'var(--gradient-orange-raspberry)',
    gradientMintBlue = 'var(--gradient-mint-blue)',
    gradientRedPurpleViolet = 'var(--gradient-red-purple-violet)',
    gradientLightBlueViolet = 'var(--gradient-light-blue-violet)',
    buttonFallbackColorHover = 'var(--button-fallback-color-hover)',
    deprecatedHeadline = 'var(--deprecated-headline)',
    gradientReferralUrlsBanner = 'var(--gradient-referral-urls-banner)',
}
